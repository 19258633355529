import _ from "lodash";
import { getContentFromContentStack } from "../core/contentStack";

export const getGroupedTipsData = async (data: any, mainCallBack?: any) => {
  let arr: any = [];
  for (let index = 0; index < data?.length; index++) {
    let Obj: any = {};
    Obj.name = data[index]?.name;
    Obj.content = data[index]?.recommendations?.content;
    if (Obj?.content && Obj?.content?.length > 0) {
      arr.push(Obj);
    }
  }

  if (arr?.length === 0) {
    return mainCallBack([]);
  }

  let newArr = [...arr];
  let items = 0;
  for (let index = 0; index < newArr?.length; index++) {
    const allTips = await getTipsData(
      newArr[index]?.content,
      // eslint-disable-next-line no-loop-func
      function callBackFunction(resp: any) {
        newArr[index] = Object.assign({}, newArr[index], {
          tipsData: resp,
        });
        items++;
        if (items === newArr?.length) {
          return mainCallBack(newArr);
        }
      }
    );
  }
};

const getAllTips = (tipsData: any) => {
  return tipsData.then(function (data: any) {
    let returnedData = data;
    return returnedData;
  });
};

const getTipsData = async (data: any, callBackFunction?: any) => {
  let arr: any = [];
  let itemsProcessed = 0;

  data?.forEach(async (element: any, index: number) => {
    let result: any;
    result = await getContent(element, index);
    if (result) {
      arr[result?.index] = result?.response;
      itemsProcessed++;
    } else {
      arr[result?.index] = result?.response;
      itemsProcessed++;
    }

    if (itemsProcessed === data?.length) {
      return callBackFunction(_.compact(arr));
    }
  });
  // for (let index = 0; index < data.length; index++) {
  //   let result: any;
  //   result = await getContent(data[index]);
  //   result && arr.push(result[0]);
  // }
};

const getContent = (contentTypeId: string, index: number) => {
  return getContentFromContentStack(contentTypeId)
    .then((response) => {
      if (response && response.error_message) {
        let Obj: any = {};
        Obj.response = undefined;
        Obj.index = index;
        return Obj;
      } else {
        //return response && response[0];
        let Obj: any = {};
        Obj.response = response && response[0][0];
        Obj.index = index;
        return Obj;
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
