import axios from "axios";
import jwt_decode from "jwt-decode";
import _ from "lodash";
import { handleUserInfo } from "../utils/userResponse";
import { generateSurveyId } from "../utils/userResponse";
import { handleUpdateUserAPI } from "./recommendationService";
/**
 * API calls to profile information of the user.
 */
export const mdmsService: any = async () => {
  let encodedData = localStorage.getItem("okta-token-storage");
  let userConsentFlag = localStorage.getObj("user-consent");
  let encodedData1 = encodedData ? JSON.parse(encodedData) : null;
  generateSurveyId();
  // used to send userInfo
  let userInfoObj: any = {
    accountId: "",
    accountName: "",
    accountSubType: "",
    country: "",
    partyId: "",
    salesPlanAff: "",
    language: "",
    aboNumber: "",
    userEmail: "",
    recoUserId: "",
    userConsent:
      userConsentFlag && userConsentFlag === "accepted" ? true : false,
    pinNumber: "",
  };
  const token =
    (encodedData1 &&
      encodedData1.accessToken &&
      encodedData1.accessToken.accessToken) ||
    "";
  const idToken = encodedData1.idToken;
  let decoded: any = token ? jwt_decode(token) : "";
  //let decodedData: any = decoded ? JSON.parse(decoded) : "";

  //API call to get access token. We do not require to make this call from the app because we can utilise the JWT token which we get in AmwayID auth.
  //This call currently only works in Postman
  // axios
  //   .post(
  //     `https://amwayconnect-amerreg-qa.oktapreview.com/oauth2/ausniwuqpv35zpr9e0h7/v1/token`,
  //     {
  //       grant_type: "password",
  //       username: "AmericasQA+3744170@gmail.com",
  //       password: "qxtr4170",
  //       scope: "openid profile mdms:uberprofile:read",
  //     },
  //     {
  //       headers: {
  //         accept: "application/json",
  //         "content-type": "application/x-www-form-urlencoded",
  //         authorization:
  //           "Basic MG9hejg2c2M0ZUJia0loNlMwaDc6VWV0OVhYd2lmMTlCTjEzNzE3NWY1X3ZqcjF5WU1WZzF1SVJ5eWJnRw==",
  //       },
  //     }
  //   )
  //   .then((res) => {
  //   });

  const partyId = idToken ? idToken.claims.partyId : ""; //"3744170"; //
  const salesPlanAff = idToken ? idToken.claims.account.sales_plan_aff : ""; //010
  userInfoObj.partyId = partyId;
  userInfoObj.salesPlanAff = salesPlanAff;
  userInfoObj.aboNumber = idToken ? idToken.claims.account.abo : "";
  userInfoObj.userEmail = idToken ? idToken.claims.email : "";
  if (partyId && salesPlanAff) {
    const AccountUrl = `${process.env.REACT_APP_MDMS_ACCOUNT_BASE_URL}${partyId}/accounts?api_key=${process.env.REACT_APP_API_KEY}&salesPlanAff=${salesPlanAff}`;
    // API call to get account information by party id
    return axios
      .get(AccountUrl, {
        headers: {
          Authorization: `Bearer ${token}`, //uncomment this code when mdms service is integrated with okta app
          //Authorization:
         //"Bearer eyJraWQiOiJYeFBaNVlOUTZXb3FnXzVOVkw3RWgwU2ticVJ5akhUQkhiXzJ2MXFaYVRVIiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULlRKMklKU2JLSWpXQzNSNlRtZHpvOTM3VktmVlFYYTA3TGp4WE41d1NPLVEiLCJpc3MiOiJodHRwczovL2Ftd2F5Y29ubmVjdC1hbWVycmVnLXFhLm9rdGFwcmV2aWV3LmNvbS9vYXV0aDIvYXVzbml3dXFwdjM1enByOWUwaDciLCJhdWQiOiJBUEktQXV0aC1TcnYtRXh0ZXJuYWwtUUEtQW1yIiwiaWF0IjoxNjI5MTkxMDc0LCJleHAiOjE2MjkxOTQ2NzQsImNpZCI6IjBvYXo4NnNjNGVCYmtJaDZTMGg3IiwidWlkIjoiMDB1cHJrbG1jbEtXM2hGSXQwaDciLCJzY3AiOlsicHJvZmlsZSIsIm1kbXM6dWJlcnByb2ZpbGU6cmVhZCIsIm9wZW5pZCJdLCJzdWIiOiJBbWVyaWNhc1FBKzM3NDQxNzBAZ21haWwuY29tIiwib3JpZ2luIjoiIiwicGFydHlJZCI6IjM3NDQxNzAiLCJtc3MiOiJhYm9OdW09OTc1MyBzYWxlc1BsYW5BZmY9MDEwIHBhcnR5SWQ9Mzc0NDE3MCIsImNsaWVudF9pZCI6IiIsImFjY291bnQiOnsiYWJvIjoiOTc1MyIsInNhbGVzX3BsYW5fYWZmIjoiMDEwIiwibGNsX3BhcnR5aWQiOiIzNzQ0MTcwIiwiY291bnRyeSI6IlVTIiwic3RhdHVzIjoiQUNUSVZFIiwicmVzaWduX2RhdGUiOiIifSwibG9naW5fcGFydHlpZCI6IjM3NDQxNzAiLCJjc3BhIjoiIiwiZ2JsX3BhcnR5aWQiOiIzNzQ0MTcwIn0.PY0GzBmF5LDSoxmoQBf72XLDMfo7E4EGnbSJ0acKVfAjI-SivdHH5O0_ScyrPy-VwSz4J6SAM-jD2zR8f06gd-bJxwnQfhbvZUhBzZqv-MdCuPEwApEYN7PisAx5YscTc14zMoqFX6KNCZM1rg7g0JHVhzZ4kPz9ey-yKTyA81kxAWv4QmMdhODuOnwKDUFjjknAl5LCZHpc_koWs7-35mI_wBP5CWbLlLne9mhzJDosrhJqSUiX_vhUAJWtkB5IsjiQ8MdUATiZxYHnNx1zbEfMLcIKANRgJuQZQoVAEA3wbmgodq2yxljwGWOUstebzUa9hXYK33x4oQj5NkHoYw",
        },
      })
      .then(async (res) => {
        // Add data from -- Get MDMS Account by Party
        if (res) {
          userInfoObj.accountId = res.data.amwayAccountList[0].accountId;
          userInfoObj.accountName = res.data.amwayAccountList[0].accountName;
          userInfoObj.accountSubType =
            res.data.amwayAccountList[0].accountSubTypeCd;
          userInfoObj.country = res.data.amwayAccountList[0].cntryCd;
          if (res && res.data && res.data.amwayAccountList) {
            const accountId =
              res && res.data ? res.data.amwayAccountList[0].accountId : "";
            //API call to get party profile of the user
            return axios
              .get(
                `${process.env.REACT_APP_MDMS_PARTY_BASE_URL}${salesPlanAff}-${accountId}/parties/${partyId}/?api_key=${process.env.REACT_APP_API_KEY}`,
                {
                  headers: {
                    Authorization: `Bearer ${token}`, //uncomment this code when mdms service is integrated with okta app
                    //Authorization:
                    //"Bearer eyJraWQiOiJYeFBaNVlOUTZXb3FnXzVOVkw3RWgwU2ticVJ5akhUQkhiXzJ2MXFaYVRVIiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULlRKMklKU2JLSWpXQzNSNlRtZHpvOTM3VktmVlFYYTA3TGp4WE41d1NPLVEiLCJpc3MiOiJodHRwczovL2Ftd2F5Y29ubmVjdC1hbWVycmVnLXFhLm9rdGFwcmV2aWV3LmNvbS9vYXV0aDIvYXVzbml3dXFwdjM1enByOWUwaDciLCJhdWQiOiJBUEktQXV0aC1TcnYtRXh0ZXJuYWwtUUEtQW1yIiwiaWF0IjoxNjI5MTkxMDc0LCJleHAiOjE2MjkxOTQ2NzQsImNpZCI6IjBvYXo4NnNjNGVCYmtJaDZTMGg3IiwidWlkIjoiMDB1cHJrbG1jbEtXM2hGSXQwaDciLCJzY3AiOlsicHJvZmlsZSIsIm1kbXM6dWJlcnByb2ZpbGU6cmVhZCIsIm9wZW5pZCJdLCJzdWIiOiJBbWVyaWNhc1FBKzM3NDQxNzBAZ21haWwuY29tIiwib3JpZ2luIjoiIiwicGFydHlJZCI6IjM3NDQxNzAiLCJtc3MiOiJhYm9OdW09OTc1MyBzYWxlc1BsYW5BZmY9MDEwIHBhcnR5SWQ9Mzc0NDE3MCIsImNsaWVudF9pZCI6IiIsImFjY291bnQiOnsiYWJvIjoiOTc1MyIsInNhbGVzX3BsYW5fYWZmIjoiMDEwIiwibGNsX3BhcnR5aWQiOiIzNzQ0MTcwIiwiY291bnRyeSI6IlVTIiwic3RhdHVzIjoiQUNUSVZFIiwicmVzaWduX2RhdGUiOiIifSwibG9naW5fcGFydHlpZCI6IjM3NDQxNzAiLCJjc3BhIjoiIiwiZ2JsX3BhcnR5aWQiOiIzNzQ0MTcwIn0.PY0GzBmF5LDSoxmoQBf72XLDMfo7E4EGnbSJ0acKVfAjI-SivdHH5O0_ScyrPy-VwSz4J6SAM-jD2zR8f06gd-bJxwnQfhbvZUhBzZqv-MdCuPEwApEYN7PisAx5YscTc14zMoqFX6KNCZM1rg7g0JHVhzZ4kPz9ey-yKTyA81kxAWv4QmMdhODuOnwKDUFjjknAl5LCZHpc_koWs7-35mI_wBP5CWbLlLne9mhzJDosrhJqSUiX_vhUAJWtkB5IsjiQ8MdUATiZxYHnNx1zbEfMLcIKANRgJuQZQoVAEA3wbmgodq2yxljwGWOUstebzUa9hXYK33x4oQj5NkHoYw",
                  },
                }
              )
              .then(async (res) => {
                // Add data from -- Get MDMS Party Profile
                userInfoObj.givenName =
                  res.data.party.nameList[0].localeName.givenName;
                userInfoObj.language = res?.data?.party?.partyMst?.languageCd;
                let fullLangName = _.includes(
                  res?.data?.party?.partyMst?.languageCd,
                  "-us"
                )
                  ? res?.data?.party?.partyMst?.languageCd
                  : res?.data?.party?.partyMst?.languageCd + "-us";
                userInfoObj.pinNumber = res?.data?.party?.partyMst?.userPin;
                sessionStorage.setItem("languageTypeOfApp", fullLangName);
                userInfoObj.recoUserId = localStorage?.getObj("reco-user-id");
                localStorage.setObj("user-info", userInfoObj);
                let userInfoData = localStorage.getObj("user-info");
                delete userInfoData.pinNumber;
                delete userInfoData.givenName;
                userInfoData = userInfoData;
                const handleUserInfoResult = await handleUserInfo(
                  userInfoData
                ).then(async (resp: any) => {
                  if(resp?.data?.createUser?.userConsent) {
                    localStorage.setObj("user-consent", "accepted");
                  }
                  if(localStorage?.getObj("user-consent") === "accepted" && !resp?.data?.createUser?.userConsent) {
                    await handleUpdateUserAPI(userInfoData)
                  }
                  // if (resp?.data?.updateRecoUserId?.status) {
                  //   if (resp?.data?.updateRecoUserId?.status === 200) {
                  //     return resp;
                  //   }
                  // } else {
                  //   if (resp?.status === 200) {
                  //     return resp;
                  //   }
                  // }
                  return resp;
                });
                return handleUserInfoResult;
              })
              .catch((err: any) => {
                return err;
              });
          }
        } else {
          userInfoObj.accountId = idToken ? idToken.claims.account.abo : "";
          localStorage.setObj("user-info", userInfoObj);
        }
      })
      .catch((err: any) => {
        return err;
      });
  }

  /*
    const accountId = "9753";
    //API call to get party profile of the user
    axios.get(`${process.env.REACT_APP_MDMS_PARTY_BASE_URL}${salesPlanAff}-${accountId}/parties/${partyId}/?api_key=${process.env.REACT_APP_API_KEY}`,
    {
      headers: {
        //'Authorization' : `Bearer ${token}` //uncomment this code when mdms service is integrated with okta app
        'Authorization' : 'Bearer eyJraWQiOiJYeFBaNVlOUTZXb3FnXzVOVkw3RWgwU2ticVJ5akhUQkhiXzJ2MXFaYVRVIiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULkN2YW41U0tMYll6OHVlQkN0RUFybXotZzc3VWlaVWVucTBhR1NocWxuTVEiLCJpc3MiOiJodHRwczovL2Ftd2F5Y29ubmVjdC1hbWVycmVnLXFhLm9rdGFwcmV2aWV3LmNvbS9vYXV0aDIvYXVzbml3dXFwdjM1enByOWUwaDciLCJhdWQiOiJBUEktQXV0aC1TcnYtRXh0ZXJuYWwtUUEtQW1yIiwiaWF0IjoxNjI1NDk5OTE2LCJleHAiOjE2MjU1MDM1MTYsImNpZCI6IjBvYXo4NnNjNGVCYmtJaDZTMGg3IiwidWlkIjoiMDB1cHJrbG1jbEtXM2hGSXQwaDciLCJzY3AiOlsib3BlbmlkIiwicHJvZmlsZSIsIm1kbXM6dWJlcnByb2ZpbGU6cmVhZCJdLCJzdWIiOiJBbWVyaWNhc1FBKzM3NDQxNzBAZ21haWwuY29tIiwib3JpZ2luIjoiIiwicGFydHlJZCI6IjM3NDQxNzAiLCJtc3MiOiJhYm9OdW09OTc1MyBzYWxlc1BsYW5BZmY9MDEwIHBhcnR5SWQ9Mzc0NDE3MCIsImNsaWVudF9pZCI6IiIsImFjY291bnQiOnsiYWJvIjoiOTc1MyIsInNhbGVzX3BsYW5fYWZmIjoiMDEwIiwibGNsX3BhcnR5aWQiOiIzNzQ0MTcwIiwiY291bnRyeSI6IlVTIiwic3RhdHVzIjoiQUNUSVZFIiwicmVzaWduX2RhdGUiOiIifSwibG9naW5fcGFydHlpZCI6IjM3NDQxNzAiLCJjc3BhIjoiIiwiZ2JsX3BhcnR5aWQiOiIzNzQ0MTcwIn0.RUDTuuI-80LaSJSEoewK_quffkyDNG7m_VlHd-K8czc8EvRVGg6iXj-_WyuNy1ayIkbz4Pv5Ilt_VvLu5IRP1k1vQ3npcuMYMyVbEKWowZbd95qB34u2_LBzr40CsowuoweF6ZtIE3A0fl_LeAwT926Qi6yZb7Ec2Zg8Lxwome3-MgEPxUX3VdyD9XHHq3WIcE2XN-3wOtHa3guNA9NoT76LXN4mfVidcNIFyYxZ4bEOswrSzo3sYdLNSuHUMgbqFFA9KvByqf3d-pTFzNjJgFwR00-68JTMKfBVV-P9ZN3oRWobmsOU-4OOZ0ZUMLG-sh2phCWsvfiJfeP1fNmT9w'
      }
    }
    )
    .then(res => {
    })
    
    */

  /**
   * set user info data
   */
};
