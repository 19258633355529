import {
  GETRECOMMENDATION,
  CREATEUSER,
  UPDATEUSERRECOID,
  GETRECOMMENDATIONBYRECOID,
  UPDATEUSER,
} from "../queries/personalized";
import client from "../utils/init-appSync";

/**
 * This method is used for handling Recommendation result API call
 * @param {any} answersObj
 *
 */

export const handleRecoResultAPI = (answersObj: any) => {
  return client
    .query({
      query: GETRECOMMENDATION,
      variables: {
        answers: answersObj,
      },
      fetchPolicy: "no-cache",
    })
    .then((res: any) => {
      // console.log("handleRecoResultAPI---response:", res);
      res.createdResultAt = answersObj.createdAt;
      localStorage.setObj("reco-result", res);
      return res;
    })
    .catch((err: any) => {
      console.log("error:", err);
      err.isError = true;
      return err;
    });
};

/**
 * This method is used for handling Recommendation result by reco id API call
 * @param {any} answersObj
 *
 */

export const handleRecoResultByRecoIdAPI = (reco_id: string) => {
  return client
    .query({
      query: GETRECOMMENDATIONBYRECOID,
      variables: {
        // recoId: "0pkw2nurwly2gu0c1z1a2i0w9ral2o6e"
        recoId: reco_id,
      },
      fetchPolicy: "no-cache",
    })
    .then((res: any) => {
      // console.log("handleRecoResultByRecoIdAPI---response:", res);
      return res;
      // res.createdResultAt = answersObj.createdAt;
      // localStorage.setObj('reco-result', res);
    })
    .catch((err: any) => {
      console.log("error:", err);
    });
};

/**
 * This method is used for handling create user API call
 * @param {any} userInfoObj
 *
 */

export const handleCreateUserAPI = async (userInfoObj: any) => {
  return client
    .mutate({
      mutation: CREATEUSER,
      variables: {
        userinfo: userInfoObj,
      },
      fetchPolicy: "no-cache",
    })
    .then((res: any) => {
      // console.log("handleCreateUserAPI---response:", res);
      res.status = 200;
      const resp = res;
      return resp;
    })
    .catch((err: any) => {
      console.log("error:", err);
    });
};

/**
 * This method is used for get data by id
 * @param {any} partyId
 *
 */

// reco_id_data: {createdAt:1629114545792 , currentRecoUserId: "1234", sessionRecoUserId: "123465"}
export const updateUserRecoId = async (updateInfoObj: any) => {
  return client
    .mutate({
      mutation: UPDATEUSERRECOID,
      variables: {
        recoIdData: updateInfoObj,
      },
      fetchPolicy: "no-cache",
    })
    .then((res: any) => {
      // console.log("updateUserRecoId >> res", res);
      // alert(updateInfoObj.currentRecoUserId)
      localStorage.setObj("reco-user-id", updateInfoObj.currentRecoUserId);
      return res;
    })
    .catch((err: any) => {
      console.log("error:", err);
    });
};

/**
 * This method is used for handling update user API call
 * @param {any} userInfoObj
 *
 */

export const handleUpdateUserAPI = async (userInfoObj: any) => {
  return client
    .mutate({
      mutation: UPDATEUSER,
      variables: {
        userinfo: userInfoObj,
      },
      fetchPolicy: "no-cache",
    })
    .then((res: any) => {
      // console.log("handleCreateUserAPI---response:", res);
      res.status = 200;
      const resp = res;
      return resp;
    })
    .catch((err: any) => {
      console.log("error:", err);
    });
};
