import { CHECKTESTDIFFSTATE } from "../../queries/admintooling";
import client from "../../utils/init-appSync";
import { validateAdminOktaSession } from "../../utils/validateOktaSession";

export const handleCheckTestDiffStateAPI = async (
  testInputObj: any,
  props: any
) => {
  return client
    .query({
      query: CHECKTESTDIFFSTATE,
      variables: {
        test_input: testInputObj,
      },
      fetchPolicy: "no-cache",
    })
    .then((res: any) => {
      const resp = res.data.checkTestDiffState;
      return resp;
    })
    .catch((err: any) => {
      if (err?.networkError?.response?.status === 401) {
        validateAdminOktaSession(props, true);
      } else {
        let isError: any = {};
        isError.error = true;
        return isError;
      }
    });
};
