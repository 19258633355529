import _ from "lodash";
import React from "react";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer";
import ToggleButton from "../../../shared-ui/toggle-button";

interface DifferenceComponentProps {
  initialInput: any;
  initialResults: any;
}

interface DifferenceComponentState {
  addedCount: number;
  removedCount: number;
  originalVersion: any;
  newVersion: any;
}

class DifferenceComponent extends React.Component<
  DifferenceComponentProps,
  DifferenceComponentState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      addedCount: 0,
      removedCount: 0,
      originalVersion: {},
      newVersion: {},
    };
  }
  componentDidUpdate = () => {
    let selectedElement: any = document?.getElementById("main-area");
    let serializedData: any = new XMLSerializer().serializeToString(
      selectedElement
    );
    let matchesCountAdded = serializedData.split("<pre>+</pre>").length - 1;
    let matchesCountRemoved = serializedData.split("<pre>-</pre>").length - 1;
    if (matchesCountAdded !== this.state.addedCount) {
      this.setState({ addedCount: matchesCountAdded });
    }
    if (matchesCountRemoved !== this.state.removedCount) {
      this.setState({ removedCount: matchesCountRemoved });
    }
  };

  omitDeep = (data: any) => {
    let self = this;
    _.forEach(data, function (value, key) {
      if (_.isObject(value)) {
        self.omitDeep(value);
      } else if (key === "__typename") {
        delete data[key];
      }
    });
    return data;
  };
  handleSort = (data: any) => {
    let sortedData = Object.keys(data)
      .sort()
      .reduce(
        (acc, key) => ({
          ...acc,
          [key]: data[key],
        }),
        {}
      );
    return sortedData;
  };

  handleNestedSort = (unordered: any, sortArrays = false) => {
    const self = this;
    if (!unordered || typeof unordered !== "object") {
      return unordered;
    }

    if (Array.isArray(unordered)) {
      const newArr: any = unordered.map((item) =>
        self.handleNestedSort(item, sortArrays)
      );
      if (sortArrays) {
        newArr.sort();
      }
      return newArr;
    }

    const ordered: any = {};
    Object.keys(unordered)
      .sort()
      .forEach((key) => {
        ordered[key] = self.handleNestedSort(unordered[key], sortArrays);
      });
    return ordered;
  };

  // }
  handleClickToggle = (selectedValue: string) => {
    const { initialInput, initialResults } = this.props;
    if (selectedValue) {
      const updatedResults: any = localStorage.getItem("meta_result");
      const parsedResults: any = JSON.parse(updatedResults);
      this.setState({
        originalVersion: _.sortBy(
          this.handleNestedSort(this.omitDeep(initialResults)),
          "name"
        ),
        newVersion: _.sortBy(
          this.handleNestedSort(
            this.omitDeep(
              parsedResults.data.getMetaRecommendation.recommendations
            )
          ),
          "name"
        ),
      });
    } else {
      const answersObj: any = localStorage.getItem("answersObj");
      let parsedAnsers: any = JSON.parse(answersObj);
      this.setState({
        originalVersion: this.handleNestedSort(this.omitDeep(initialInput)),
        newVersion: this.handleNestedSort(this.omitDeep(parsedAnsers)),
      });
    }
  };

  render() {
    const defaultStyles = {
      variables: {
        light: {
          diffViewerBackground: "#fff",
          diffViewerColor: "#212529",
          addedBackground: "#F0FAE5",
          addedColor: "#24292e",
          removedBackground: "#FDF4F4",
          removedColor: "#24292e",
          wordAddedBackground: "#E2F5CE",
          wordRemovedBackground: "#FBE9E9",
          addedGutterBackground: "#E6EFDB",
          removedGutterBackground: "#F2E9E9",
          gutterBackground: "#F4F4F4;",
          gutterBackgroundDark: "#f3f1f1",
          highlightBackground: "#fffbdd",
          highlightGutterBackground: "#fff5b1",
          codeFoldGutterBackground: "#E4E4E4",
          codeFoldBackground: "#FAFAFA",
          emptyLineBackground: "#fafbfc",
          gutterColor: "#212529",
          addedGutterColor: "#212529",
          removedGutterColor: "#212529",
          codeFoldContentColor: "#212529",
          diffViewerTitleBackground: "#fafbfc",
          diffViewerTitleColor: "#212529",
          diffViewerTitleBorderColor: "#eee",
        },
        dark: {
          diffViewerBackground: "#2e303c",
          diffViewerColor: "#FFF",
          addedBackground: "#044B53",
          addedColor: "white",
          removedBackground: "#632F34",
          removedColor: "white",
          wordAddedBackground: "#055d67",
          wordRemovedBackground: "#7d383f",
          addedGutterBackground: "#034148",
          removedGutterBackground: "#632b30",
          gutterBackground: "#2c2f3a",
          gutterBackgroundDark: "#262933",
          highlightBackground: "#2a3967",
          highlightGutterBackground: "#2d4077",
          codeFoldGutterBackground: "#21232b",
          codeFoldBackground: "#262831",
          emptyLineBackground: "#363946",
          gutterColor: "#464c67",
          addedGutterColor: "#8c8c8c",
          removedGutterColor: "#8c8c8c",
          codeFoldContentColor: "#555a7b",
          diffViewerTitleBackground: "#2f323e",
          diffViewerTitleColor: "#555a7b",
          diffViewerTitleBorderColor: "#353846",
        },
      },
    };
    const { originalVersion, newVersion } = this.state;

    return (
      <div className="difference-container" id="main-area">
        <div className="difference-header-wrapper">
          <div className="label">Differences</div>
          <div className="diff-filter-wrapper">
            <div className="toggle-area">
              <ToggleButton
                {...this.props}
                handleOnClickToggle2={(selectedValue: any) => {
                  this.handleClickToggle(selectedValue);
                }}
                isChecked={false}
                lableLeft={"Results"}
                lableRight={"Input"}
                type1="input" // for right type
                type2="results" // for left type
              />
            </div>
            <div className="count-area">
              <div className="count">Removed: {this.state.removedCount}</div>
              <div className="count">Added: {this.state.addedCount}</div>
            </div>
          </div>
        </div>
        <ReactDiffViewer
          oldValue={JSON.stringify(originalVersion, undefined, 4)}
          newValue={JSON.stringify(newVersion, undefined, 4)}
          splitView={true}
          compareMethod={DiffMethod.LINES}
          styles={defaultStyles}
          leftTitle="Original Version"
          rightTitle="New Version"
        />
      </div>
    );
  }
}

export default DifferenceComponent;
