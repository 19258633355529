import _ from "lodash";
import {
  fetchProductDetails,
  fetchProductReview,
} from "../services/commerceServices/productDetails";

export const getGroupedProductsData = async (data: any, callBackMain: any) => {
  let arr: any = [];
  let flag: any = 0;
  for (let index = 0; index < data?.length; index++) {
    let Obj: any = {};
    Obj.name = data[index]?.name;
    Obj.products = data[index]?.recommendations?.products;
    if (Obj?.products && Obj?.products?.length > 0) {
      arr.push(Obj);
    }
  }
  if (arr?.length === 0) {
    return callBackMain([]);
  }
  let newArr = [...arr];

  // for (let index = 0; index < newArr.length; index++) {
  //   const allProducts = getProdsData(newArr[index].products);
  //   const names = await getAllProducts(allProducts);
  //   newArr[index] = Object.assign({}, newArr[index], {
  //     productsData: names,
  //   });
  // }
  let items1 = 0;
  for (let index = 0; index < newArr?.length; index++) {
    // const allProducts = getProdsData(newArr[index].products);
    // const names = await getAllProducts(allProducts);
    // newArr[index] = Object.assign({}, newArr[index], {
    //   productsData: names,
    // });

    const allProducts = await getProdsData(
      newArr[index]?.products,
      // eslint-disable-next-line no-loop-func
      function callBackProducts(resp: any) {
        newArr[index] = Object.assign({}, newArr[index], {
          productsData: resp,
        });
        items1++;
        if (items1 === newArr?.length) {
          flag++;
          if (flag === 2) {
            combineProductData();
          }
        }
      }
    );
  }

  // for (let index = 0; index < newArr.length; index++) {
  //   const allProductsReviews = getProdsReviews(newArr[index].products);
  //   const names = await getAllProductsRatings(allProductsReviews);
  //   newArr[index] = Object.assign({}, newArr[index], {
  //     productsReviews: names,
  //   });
  // }
  let items2 = 0;
  for (let index = 0; index < newArr?.length; index++) {
    // const allProducts = getProdsData(newArr[index].products);
    // const names = await getAllProducts(allProducts);
    // newArr[index] = Object.assign({}, newArr[index], {
    //   productsData: names,
    // });

    const allProductsReviews = await getProdsReviews(
      newArr[index]?.products,
      // eslint-disable-next-line no-loop-func
      function callBackReviews(resp: any) {
        newArr[index] = Object.assign({}, newArr[index], {
          productsReviews: resp,
        });
        items2++;
        if (items2 === newArr?.length) {
          flag++;

          if (flag === 2) {
            combineProductData();
          }
        }
      }
    );
  }

  const combineProductData = () => {
    for (let index = 0; index < newArr.length; index++) {
      const element: any = newArr[index];

      let data = element?.productsData;
      let reviews = element?.productsReviews;

      if (data) {
        let items = data?.map((id: any, index: number) => {
          return {
            data: id,
            ...(reviews && reviews[index] && { reviews: reviews[index] }),
          };
        });

        newArr[index] = Object.assign({}, newArr[index], {
          productsCompleteData: items,
        });
      }
      //else {
      //   newArr[index] = Object.assign({}, newArr[index], {
      //     productsCompleteData: { data: undefined, reviews: undefined },
      //   });
      // }
    }
    let tempArr = [];
    for (let index = 0; index < newArr?.length; index++) {
      let element = newArr[index];
      let result = _.pick(element, [
        "name",
        "products",
        "productsCompleteData",
      ]);

      tempArr.push(result);
    }

    return callBackMain(tempArr);
  };
};

const getAllProducts = (productsData: any) => {
  return productsData.then(function (data: any) {
    let returnedData = data;
    return returnedData;
  });
};

const getProdsData = async (data: any, callBackProducts?: any) => {
  if (data?.length === 0) {
    return callBackProducts([]);
  } else {
    let arr: any = [];
    let itemsProcessed = 0;
    /*asynchronous call added for optimization*/
    data.forEach(async (element: any, index: number) => {
      let result: any;
      result = await getDetails(element, index);
      if (result) {
        arr[result?.index] = result?.response;
        itemsProcessed++;
      } else {
        arr[result?.index] = result?.response;
        itemsProcessed++;
      }

      if (itemsProcessed === data?.length) {
        return callBackProducts(arr);
      }
    });
  }

  //return arr;
};

const getDetails = (Id: any, index: number) => {
  return fetchProductDetails(Id)
    .then((response: any) => {
      if (response === 400) {
        let Obj: any = {};
        Obj.response = { name: "NOT FOUND", id: Id };
        Obj.index = index;
        return Obj;
      } else {
        let Obj: any = {};
        Obj.response = isNaN(response) ? response : undefined;
        Obj.index = index;
        return Obj;
      }
    })
    .catch((error: any) => {
      console.log(error);
    });
};

const getAllProductsRatings = (productsData: any) => {
  return productsData.then(function (data: any) {
    let returnedData = data;
    return returnedData;
  });
};

const getProdsReviews = async (data: any, callBackReviews: any) => {
  if (data?.length === 0) {
    return callBackReviews([]);
  } else {
    let arr: any = [];
    let itemsProcessed = 0;
    /*asynchronous call added for optimization*/
    data.forEach(async (element: any, index: number) => {
      let result: any;
      result = await getReviews(element, index);

      if (result) {
        itemsProcessed++;
        arr[result?.index] = result?.response;
      } else {
        arr[result?.index] = result?.response;
        itemsProcessed++;
      }

      if (itemsProcessed === data?.length) {
        return callBackReviews(arr);
      }
    });
  }

  // for (let index = 0; index < data.length; index++) {
  //   let result: any;
  //    result = await getReviews(data[index]);
  //    arr.push(result);
  // }

  //return arr;
};

const getReviews = (Id: any, index: number) => {
  return fetchProductReview(Id)
    .then((response: any) => {
      if (response && response.error_message) {
        let Obj: any = {};
        Obj.response = undefined;
        Obj.index = index;
        return Obj;
        // console.log("error is", response.error_message);
      } else {
        //return response && response;
        let Obj: any = {};
        Obj.response = response && response;
        Obj.index = index;
        return Obj;
      }
    })
    .catch((error: any) => {
      console.log(error);
    });
};
