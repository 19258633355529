import { DELETETESTSCENARIO } from "../../queries/admintooling";
import client from "../../utils/init-appSync";
import { validateAdminOktaSession } from "../../utils/validateOktaSession";

export const handleDeleteTestScenarioAPI = async (deleteDataObj: any,props:any) => {
  return client
    .mutate({
      mutation: DELETETESTSCENARIO,
      variables: {
        delete_input: deleteDataObj,
      },
      fetchPolicy: "no-cache",
    })
    .then((res: any) => {
      return res;
    })
    .catch((err: any) => {
      console.log("error:", err);
      if(err?.networkError?.response?.status===401){
        validateAdminOktaSession(props,true)
      }else{
        let isError=err
        isError.error=true
        return isError
      }
      // return err;
    });
};
