export default {
  es: {
    common: {
      bottom_section_i_agree: "Acepto",
      bottom_section_continue: "Continuar",
      bottom_section_finish: "Finalizar",
      bottom_section_email_copy_label:
        "Envíeme una copia por correo electrónico",
      bottom_section_back_label: "Volver",
      already_have_account_label: "¿Ya tienes una cuenta?",
      sign_in_to_amway: "Inicia sesión en Amway",
      first_things_first_label: "Lo primero es lo primero.",
      why_are_we_asking_this: "¿Por qué pedimos esto?",
      enter_your_email_id: "Ingrese su ID de correo electrónico",
      enter_email_placeholder: "ejemplo@email.com",
      send_btn_label: "Enviar",
      spanish_label: "Español",
      english_label: "English",
      email_sent_label: "Email enviado.",
      height_title_label: "Altura",
      feet_unit_label: "pie",
      inch_unit_label: "en",
      feet_and_pound_unit_label: "PIE / LIBRAS",
      centimeter_and_kilogram_unit_label: "CM / KG",
      login_label: "Acceso",
      logout_label: "Cerrar sesión",
      no_i_like_everything: "No, me gusta todo",
      my_results: "Mis resultados",
      my_foods: "Mis alimentos",
      my_products: "Mis productos",
      my_tips: "Mis consejos",
      all_foods: "Todos los alimentos",
      all_tips: "Todos los consejos",
      all_products: "Todos los productos",
      for_your: "Para tu",
      foods_to_feast: "Con qué comenzar",
      best_product: "El mejor producto para ti",
      best_products: "Los mejores productos para ti",
      tips_to_try: "Consejos para ti",
      view_all_foods: "Explora más alimentos",
      view_all_products: "Explora más productos",
      view_all_tips: "Descubre más consejos",
      where_to_start: "Dónde comenzar",
      see_recipes: "Ver por qué",
      see_why: "Ver por qué",
      learn_more_home: "conocer más",
      best_products_for_you: "Los mejores productos para ti",
      your_top_products: "Tus productos principales",
      other_products: "Otros productos",
      add_to_cart: "Agregar al carrito",
      filters: "Filtros",
      wellness_areas: "Áreas de bienestar",
      link_copied: "¡Este enlace se ha copiado a tu portapapeles!",
      user_added_product_to_cart: "¡Bien! Producto agregado al carro.",
      user_deleted_product_from_cart: "Este producto ha sido removido.",
      dyk_text: "¿Sabías qué?",
      welcome_back: "¡Bienvenido nuevamente!",
      guest_expiry_message:
        "¡Oh no! No pudimos guardar la información proporcionada. Inicia sesión para guardar tu progreso.",
      sign_in: "Iniciar sesión",
      sign_out: "Cerrar sesión",
      login_session_expiry_message:
        "Ya que estuviste fuera por un buen tiempo, cerramos tu sesión en la Wellness Recommender. ¡Inicia sesión de nuevo para continuar!",
      save_result_message:
        "¿Deseas guardar tus recomendaciones para la próxima vez? Continúa y crea una cuenta.",
      save_result: "Guarda tus resultados",
      hold_on: "¡Espera!",
      retake_assessment_message:
        "¿Estás seguro de que quieres empezar desde el principio? Perderás tus resultados anteriores.",
      retake_assessment: "Retomar evaluación",
      share_assessment: "Compartir evaluación",
      go_to_my_result: "Mis resultados",
      share_the_assessment: "Comparte la evaluación",
      take_survey: "Completa la evaluación",
      my_account: "Mi cuenta",
      i_like_everything: "¡Me gusta todo!",
      no_allergies_for_me: "¡No tengo alergias!",
      subtotal: "Subtotal",
      excluding_taxes_and_other_charges:
        "(No incluye impuestos y otros cargos)",
      amperks_points_earned: "Puntos AmPerks ganados",
      checkout: "Pagar",
      create_an_account: "Crea una cuenta",
      want_to: "¿Quieres",
      before_you_get_started: "antes de comenzar?",
      take_assessment: "Completa la evaluacion",
      all_foods_to_eat: "Alimentos Que debes Comer",
      all_foods_to_skip: "Alimentos que debes limitar",
      product_recommendation_message:
        "Lo sentimos, no tenemos ninguna recomendación de productos aquí debido a algunas de tus respuestas. Consulta las recomendaciones en tus otras áreas de bienestar.",
      products_recommendation_message:
        "Lo sentimos, no tenemos ninguna recomendación de productos aquí debido a algunas de tus respuestas.  ",
      close: "Cerrar",
      take_me_to_products: "Ir a la sección de productos",
      my_cart: "Mi carrito",
      items: "artículos",
      item: "artículo",
      what_to_eat: "Qué comer",
      what_to_avoid: "Qué limitar",
      why: "Por qué",
      recipe: "Receta",
      return_to_my_results: "Volver a mis resultados",
      dont_go: "¡Espera, no te vayas!",
      you_want_to_leave: "¿Estás seguro de que quieres salir? ",
      stay_here: "No, deseo permanecer aquí",
      yes_leave: "Sí, deseo salir",
      footer_statement:
        "†Esta afirmación no ha sido evaluada por la Administración de Alimentos y Medicamentos de EUA. Este producto no pretende diagnosticar, tratar, curar ni prevenir ninguna enfermedad.",
      footer_copyright: "Derechos de Autor © 2021 Amway.",
      footer_privacy_notice: "Aviso de Privacidad",
      footer_policy_security: "Política y Seguridad",
      footer_terms_of_use: "Términos de Uso",
      ingredients: "Ingredientes",
      instructions: "Instrucciones",
      faqs: "Preguntas frecuentes",
      serving: "porción",
      servings: "porciones",
      product_details: "Detalles del producto",
      delete_item_from_cart: "eliminar artículo del carrito",
      skip_to_take_survey: "Omitir para tomar evaluación",
      wellness_area: "Zona de bienestar",
      keep_going: "¡Sigue adelante!",
      copy_link_text: "Copiar",
      link_popup_title: "Enlace compartible",
      reviews: "Reseñas",
      of: "de",
      tips: "Consejos",
      foods: "Alimentos",
      products: "Productos",
      start_the_assessment: "Iniciar evaluación",
      get_started: "Comienza ya",
      out_of_stock: "Se agotaron temporalmente las existencias",
      learn_more_product: "Conoce más",
      skip_to_keep_going: "Omite para continuar",
      skip_to_my_results: "Pasar a mis resultados",
    },
    error: {
      please_enter_name: "Ingrese un nombre para continuar",
      please_enter_email: "Ingrese una ID de correo electrónico para continuar",
      please_enter_valid_email:
        "Ingrese una ID de correo electrónico válida para continuar",
      we_would_like_to_know_this: "Nos gustaría saber esto",
      please_select_atleast_one_option: "Seleccione al menos una opción",
      please_select_one_of_the_options:
        "Por favor seleccione una de las opciones.",
      add_recommendations: "¡Agrega recomendaciones a tu carrito!",
      connection_error: "¡Error de conexión! Intenta volver a conectarte.",
      error_button_try_again: "Inténtalo de nuevo",
      no_recipe_message_title: "¡No se necesita receta!",
      no_recipe_message:
        "Hay beneficios al incluir este artículo en tu dieta regular, así que agrégalo a tu lista de compras esta semana y ¡disfrútalo!",
      page_doesnt_exist: "¡Lo sentimos! Esa página no existe.",
      got_to_home_page: "Ir a la página principal",
      product_not_available: "Este producto no está disponible",
      product_fallback_details:
        "Este producto no se ha lanzado todavía, pero lo tendremos pronto. Revisa tus recomendaciones de los otros productos.",
      sku_id: "SKU ID",
      api_fail_error: "¡Ups! Volvamos a encaminarte.",
    },
  },
};
