import { clearSession } from "./clearSession";
import { compareExpiryTime, compareGuestExpiryTime, setGuestExpiryTime } from "./handleExpiryTime";

export function handleGuestExpiryPopup(props:any){
if(!localStorage.getItem("okta-token-storage") && !localStorage.getItem("guestExpiryTime") && (window.location.pathname !== "/") && window.location.pathname !== "/home"){
    setGuestExpiryTime()
  }

//   //handles guest expiry time
//   let gusetExpiryTime:any
//   if (compareGuestExpiryTime() > 0) {
//    gusetExpiryTime = setTimeout(() => {
//     clearSession(props);
//   }, compareGuestExpiryTime() * 1000);
// }
//   //clear the timeouts set when user get logged in
//   if (localStorage.getItem("okta-token-storage")) {
//     localStorage.removeItem("guestExpiryTime")
//     clearTimeout(gusetExpiryTime);
//   }
let gusetExpiryTime:any
if (localStorage.getItem("guestExpiryTime")) {
  if (compareGuestExpiryTime() > 0) {
    gusetExpiryTime = setTimeout(() => {
      clearSession(props);
    }, compareGuestExpiryTime() * 1000);
  } else {
    clearSession(props);
  }

}

    //clear the timeouts set when user get logged in
    if (localStorage.getItem("okta-token-storage")) {
      localStorage.removeItem("guestExpiryTime");
      clearTimeout(gusetExpiryTime);
    }

}

 