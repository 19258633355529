import _ from "lodash";
import { isArray, isNumber, isString } from "lodash";

export const getTagsList = () => {
  let arrayTags: any = [];
  const answersObj: any = localStorage.getItem("answersObj");
  const metaData: any = localStorage.getItem("meta_result");
  const parsedMetaData: any = JSON.parse(metaData);
  const persona: any =
    parsedMetaData?.data?.getMetaRecommendation?.metadata?.primary_persona;

  const parsedAnswersObj: any = JSON.parse(answersObj);
  for (const [key, value] of Object.entries(parsedAnswersObj)) {
    if (key === "NeedstateRank") {
      for (const [keyRank, valueRank] of Object.entries(Object(value))) {
        arrayTags.push(getNeedStateName(String(valueRank)));
      }
    } else if (
      key === "Allergy" &&
      isArray(value) &&
      value.length > 0 &&
      value[0]?.toLowerCase() !== "none"
    ) {
      arrayTags.push(key);
    } else if (
      key === "DietRestrictions" &&
      isArray(value) &&
      value.length > 0 &&
      value[0].toLowerCase() !== "none"
    ) {
      arrayTags.push("Dietary Restriction");
    } else if (
      key === "FoodDislikes" &&
      isArray(value) &&
      value.length > 0 &&
      value[0].toLowerCase() !== "none"
    ) {
      arrayTags.push("Dislike");
    } else if (key === "short_survey") {
      if (value === 0) {
        arrayTags.push("Holistic Path");
      } else {
        arrayTags.push("Focused Path");
      }
    }
    // else if(key==="")
    // else {
    //   if (isArray(value) && value.length > 0 && value[0] !== "None") {
    //     arrayTags.push(key);
    //   }
    //   if (isNumber(value) && value !== 0) {
    //     arrayTags.push(key);
    //   }
    // }
  }
  arrayTags.push(getPersonaName(persona));
  //const finalTags = _.without(arrayTags, "short-survey");

  return _.compact(arrayTags);
};

export const getNeedStateName = (name: string) => {
  switch (name) {
    case "stress-mood":
      return "Stress & Mood";
    case "sleep":
      return "Sleep";
    case "energy":
      return "Energy";
    case "immunity":
      return "Immunity";
    case "focus-memory":
      return "Focus & Memory";
    case "digestive-health":
      return "Digestive Health";
    case "vision":
      return "Vision";
    case "bone-joint":
      return "Bone & Joint";
    case "heart-health":
      return "Heart Health";
    case "beauty":
      return "Beauty";
    case "womens-health":
      return "Women's Heath";
    case "mens-health":
      return "Men's Heath";
    case "sports-nutrition":
      return "Sports Nutrition";
    case "healthy-eating":
      return "Healthy Eating";
    case "everyday-health":
      return "Everyday Health";
    default:
      return "";
  }
};

const getPersonaName = (name: string) => {
  switch (name) {
    case "maximizer":
      return "Maximizer";
    case "manager":
      return "Manager";
    case "every-day":
      return "Everyday-Jane";
    default:
      return "";
  }
};

export const getFilterListValues = [
  {
    Path: ["Holistic Path", "Focused Path"],
  },
  {
    Needstate: [
      "Stress & Mood",
      "Sleep",
      "Energy",
      "Immunity",
      "Focus & Memory",
      "Digestive Health",
      "Vision",
      "Bone & Joint",
      "Heart Health",
      "Beauty",
      "Women's Heath",
      "Men's Heath",
      "Sports Nutrition",
      "Healthy Eating",
      "Everyday Health",
    ],
  },
  {
    Persona: ["Everyday Jane", "Manager", "Maximizer"],
  },
  {
    Restriction: ["Allergy", "Dietary Restriction", "Dislike"],
  },
];
