import i18n from 'i18next'
import en from './locales/en-us'
import es from './locales/es-us'
const options = {
  lng: sessionStorage.getItem("languageTypeOfApp") || "en-us",
  debug: true,
  resources: {
    en: {
      common: en.en
    },
    es:{
      common: es.es
    }
  },
  fallbacklang: 'en',
  ns: ['common'],
  defaultNS: 'common'
}

i18n
  .init(options)

export default i18n;