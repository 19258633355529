import jwt_decode from "jwt-decode";
import suggesticClient from "../utils/suggesticClient";
import _ from "lodash";
import {
  FETCH_RECIPE_BY_INGRIDIENTS,
  SET_USER_RESTRICTION,
  CREATE_USER,
  SET_USER_PROGRAM,
  GET_RESTRICTIONS,
  FETCH_USER_PROFILE,
  GET_PROGRAMS,
  SET_CUSTOM_ATTRIBUTES,
  UPDATE_USER_LANGUAGE,
} from "../queries/suggestic";
import { uniqueUserId } from "../utils/generateUniqueId";

/**
 * Procedure to fetch data from suggestic:
 * 1.createSuggesticUser : creates new user
 * 2.searchUserProfile : used to fetch userId with help of which other queries works
 * 3.By following above two steps rest of the queries can be fetched as per need
 *
 */

/**
 * This function returns the user email id
 */
export function getUserEmailId(restrictions?: String[]) {
  let encodedData = localStorage.getItem("okta-token-storage");
  let encodedData1 = encodedData ? JSON.parse(encodedData) : null;
  const token =
    (encodedData1 &&
      encodedData1.accessToken &&
      encodedData1.accessToken.accessToken) ||
    "";
  let email = "";
  let decoded: any = token ? jwt_decode(token) : "";
  const partyId = decoded ? decoded.partyId : ""; //"3744170";
  localStorage.removeItem("sg-user");

  if (encodedData) {
    //email id generates for logged in user
    localStorage.setItem("partyId", partyId);
    email = `${localStorage.getItem("partyId") || ""}@amway.com`;
  } else {
    //email id generates for guest user
    // uniqueUserId();
    email = `${localStorage.getItem("uniqueId") || ""}@amway-temp.com`;
  }

  return email;
}

/**
 * This function returns current user language
 */
export function getUserLanguage() {
  const currentLanguage =
    sessionStorage.getItem("languageTypeOfApp") || "en-us";
  if (currentLanguage === "en-us") {
    return "EN";
  }
  if (currentLanguage === "es-us") {
    return "ES";
  }
}

/**
 * This function returns the restriction Ids
 */
export async function getRestrictionsId(restrictions?: String[]) {
  let restriction: any = [];
  const data = JSON.parse(localStorage.getItem("user-response") || "[]");
  const arr = ["FoodDislikes", "Allergy"];
  await _.forEach(arr, (value) => {
    const restrictData = data.find((obj: any) => {
      return obj.recoId === value;
    });
    _.forEach(restrictData && restrictData.recoValues, (id) => {
      if (!restriction.includes(id)) {
        restriction.push(id);
      }
    });
  });
  await getAllPrograms().then((res: any) => {
    // let programsResponse = res;
    const programData = JSON.parse(
      localStorage.getItem("user-response") || "[]"
    );
    const programs = programData.find((obj: any) => {
      return obj.recoId === "DietRestrictions";
    });

    _.forEach(
      _.slice(programs?.recoValues, 1, programs?.recoValues?.length),
      (value, index) => {
        const programName = programData.find((obj: any) => {
          return obj.id === value;
        });
        if (!restriction.includes(programName?.id)) {
          restriction = _.union(restriction, programName?.restrictions);
        }
      }
    );
  });

  return restriction;
}

/**
 * This function returns the program Ids
 */
export function getCustomAttributes() {
  const customAttributes: any = [];
  const data = JSON.parse(localStorage.getItem("user-response") || "[]");
  const attributes = data.find((obj: any) => obj.recoId === "NeedStateRank");
  _.forEach(attributes && attributes.recoValues, (value) => {
    let Obj: any = {};
    Obj.name = value;
    Obj.dataType = "BOOLEAN";
    Obj.value = true;
    customAttributes.push(Obj);
  });

  return customAttributes;
}

/**
 * This function returns the program Ids
 */
export function setProgramIds() {
  const data = JSON.parse(localStorage.getItem("user-response") || "[]");
  const programs = data.find((obj: any) => {
    return obj.recoId === "DietRestrictions";
  });
  return programs?.recoValues?.[0];
}

/**
 * This function returns the ingredients
 */
export function getIngredients(ingredients?: String[]) {
  const ingredient = ["salmon"];
  return ingredient;
}

/**
 * This function cnpm stareates new user in suggestic
 */
export function createSuggesticUser(client = suggesticClient) {
  let userName = "";
  if (localStorage.getItem("okta-token-storage")) {
    userName = localStorage.getItem("partyId") || "";
  } else {
    userName = localStorage.getItem("uniqueId") || "";
  }
  return client
    .query({
      query: CREATE_USER,
      variables: {
        name: userName,
        email: getUserEmailId(),
      },
      fetchPolicy: "no-cache",
    })
    .then((res: any) => {
      searchUserProfile();
      return res;
    })
    .catch((err: any) => {
      console.log(err);
    });
}

/**
 * This function seacrh user profile in suggestic
 */
export function searchUserProfile(client = suggesticClient) {
  let userId = "";
  return client
    .query({
      query: FETCH_USER_PROFILE,
      variables: {
        email: getUserEmailId(),
      },
      fetchPolicy: "no-cache",
    })
    .then((res: any) => {
      userId =
        res &&
        res.data &&
        res.data.searchProfile &&
        res.data.searchProfile.userId;
      localStorage.setItem("sg-user", userId);
      setUserLanguage();
    })
    .catch((err: any) => {
      console.log(err);
    });
}

/**
 * This function set food restrictions for the user in suggestic
 */
export function setProfileCustomAttribute(client = suggesticClient) {
  return client
    .query({
      query: SET_CUSTOM_ATTRIBUTES,
      variables: {
        attributes: getCustomAttributes(),
      },
      fetchPolicy: "no-cache",
    })
    .then((res: any) => {
      return res;
    })
    .catch((err: any) => {
      console.log(err);

      return err;
    });
}

/**
 * This function set food restrictions for the user in suggestic
 */
export function setUserLanguage(client = suggesticClient) {
  return client
    .query({
      query: UPDATE_USER_LANGUAGE,
      variables: {
        language: getUserLanguage(),
      },
      fetchPolicy: "no-cache",
    })
    .then((res: any) => {
      return res;
    })
    .catch((err: any) => {
      console.log(err);
      return err;
    });
}

/**
 * This function fetches all programs from suggestic
 */
export function getAllPrograms(client = suggesticClient) {
  return client
    .query({
      query: GET_PROGRAMS,
      fetchPolicy: "no-cache",
    })
    .then((res: any) => {
      const data =
        res && res.data && res.data.programs && res.data.programs.edges;
      const programs = [];
      for (let i = 0; i < data?.length; i = i + 1) {
        programs.push(data[i].node);
      }
      // console.log("response",JSON.stringify(programs))
      return programs;
    })
    .catch((err: any) => {
      console.log(err);
      return err;
    });
}

/**
 * This function set program for the the user in suggestic
 */
export function setUserProgram(programId?: any, client = suggesticClient) {
  return client
    .query({
      query: SET_USER_PROGRAM,
      variables: {
        programId: setProgramIds(),
      },
      fetchPolicy: "no-cache",
    })
    .then((res: any) => {
      return res;
    })
    .catch((err: any) => {
      console.log(err);
      return err;
    });
}

/**
 * This function used to fetch the restrictions from suggestic
 */
export function getAllRestrictions(client = suggesticClient) {
  return client
    .query({
      query: GET_RESTRICTIONS,
      fetchPolicy: "no-cache",
    })
    .then((res: any) => {
      const data =
        res && res.data && res.data.restrictions && res.data.restrictions.edges;
      const restrictions = [];
      for (let i = 0; i < data.length; i = i + 1) {
        restrictions.push(data[i].node);
      }

      return res;
    })
    .catch((err: any) => {
      console.log(err);
      return err;
    });
}

/**
 * This function set food restrictions for the user in suggestic
 */
export async function setUserRestriction(client = suggesticClient) {
  return client
    .query({
      query: SET_USER_RESTRICTION,
      variables: {
        restrictions: await getRestrictionsId(),
      },
      fetchPolicy: "no-cache",
    })
    .then((res: any) => {
      return res;
    })
    .catch((err: any) => {
      console.log(err);
      return err;
    });
}

/**
 * This function fetch data for recipie according to the ingredients from suggestic
 */
// export function searchByIngredients(
//   ingredients?: any,
//   client = suggesticClient
// ) {
//   return client
//     .query({
//       query: FETCH_RECIPE_BY_INGRIDIENTS,
//       variables: {
//         query: ingredients,
//         first: 1,
//       },
//       fetchPolicy: "no-cache",
//     })
//     .then((res: any) => {
//       return res;
//     })
//     .catch((err: any) => {
//       console.log(err);
//       return err;
//     });
// }

//This has to be used

export function searchByIngredients(
  ingredients?: any,
  client = suggesticClient
) {
  return client
    .query({
      query: FETCH_RECIPE_BY_INGRIDIENTS,
      variables: {
        first: 10,
        filter: {
          must: [{ ingredients: ingredients }],
          should: [
            { mealTime: "BREAKFAST" },
            { mealTime: "LUNCH" },
            { mealTime: "DINNER" },
          ],
        },
      },
      fetchPolicy: "no-cache",
    })
    .then((res: any) => {
      return res;
    })
    .catch((err: any) => {
      console.log(err);
      return err;
    });
}

/**
 * This function fetch data for recipie from suggestic
 */
export async function suggesticCalls() {
  const surveyType = localStorage.getItem("surveyType")
  if(surveyType === "fullSurvey"){
  if (
    !localStorage.getItem("sg-user") ||
    localStorage.getItem("okta-token-storage")
  ) {
    await createSuggesticUser();
  }

  await searchUserProfile().then(async () => {
    if (localStorage.getItem("user-response")) {
      await setProfileCustomAttribute();
      await setUserProgram();
      await setUserRestriction();
    }
  });
}
}
