import { CREATETESTSCENARIO } from "../../queries/admintooling";
import client from "../../utils/init-appSync";
import { validateAdminOktaSession } from "../../utils/validateOktaSession";

export const handleCreateTestScenarioAPI = async (metaRecoInputObj: any,props:any) => {
  return client
    .mutate({
      mutation: CREATETESTSCENARIO,
      variables: {
        metarecoinput: metaRecoInputObj,
      },
      fetchPolicy: "no-cache",
    })
    .then((res: any) => {
      const resp = res.data.createTestScenario;
      return resp;
    })
    .catch((err: any) => {
      if(err?.networkError?.response?.status===401){
        validateAdminOktaSession(props,true)
      }else{
        let isError=err
        isError.error=true
        return isError
      }
      // console.log("error:", err);
    });
};
