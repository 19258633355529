import axios from "axios";
import { AxiosResponse, AxiosError } from "axios";
import { AnyARecord } from "dns";
import { validateCommerceSession } from "../../utils/validateCommerceSession";
import { getLanguage } from "./accessToken";
let qs = require("qs");

/**
 * API call to fetch product details
 */
export async function fetchProductDetails(productCode: any) {
  await validateCommerceSession();

  const tokens = JSON.parse(
    localStorage.getItem("commerce_token_storage") || "[]"
  );
  const { idToken, accessToken } = tokens;
  let data = qs.stringify({});
  let config: any = {
    method: "get",
    url: `${process.env.REACT_APP_PRODUCT_DETAILS_URL}/v2/${
      process.env.REACT_APP_BASE_SITE_ID
    }/users/${
      idToken && idToken.partyId
    }/products/${productCode}?lang=${getLanguage()}`,
    headers: {
      "Content-Type": "application/json",
      id_token: `${idToken && idToken.id_token}`,
      Authorization: `Bearer ${accessToken && accessToken.access_token}`,
    },
    data: data,
  };

  return axios(config)
    .then((response: AxiosResponse) => {
      //return response
      return response.data;
    })
    .catch((error: AxiosError) => {
      //return error code
      return error.response!.status;
    });
}

/**
 * API call to fetch product review detail
 */
export async function fetchProductReview(id: any) {
  await validateCommerceSession();

  let config: any = {
    method: "get",
    // url: `${process.env.REACT_APP_PRODUCT_REVIEW_URL}?passkey=${
    //   process.env.REACT_APP_PRODUCT_REVIEW_PASS_KEY
    // }&apiversion=5.4&stats=Reviews&filter=productid:${id}`,
    url: `${process.env.REACT_APP_PRODUCT_REVIEW_URL}?passkey=${process.env.REACT_APP_PRODUCT_REVIEW_PASS_KEY}&apiversion=5.4&stats=Reviews&filter=productid:${id}`,
  };

  return axios(config)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
    });
}
