import { getContentFromContentStack } from "../core/contentStack";

export const getNeedStatesInfo = async (data: any) => {
  let result = await getContent(data);
  return result && result[0];
};

const getContent = (contentTypeId: string) => {
  return getContentFromContentStack(contentTypeId)
    .then((response) => {
      if (response && response.error_message) {
        console.log("error in response is", response.error_message);
        let arr: any = [];
        arr[0] = { isError: true };
        return arr;
      } else {
        return response && response[0];
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
