import _ from "lodash";
import { getContentFromContentStack } from "../core/contentStack";
import {
  fetchProductDetails,
  fetchProductReview,
} from "../services/commerceServices/productDetails";
import { searchByIngredients } from "../services/suggesticServices";

export const getGroupedFoodsData = async (data: any, callBackMain: any) => {
  let arr: any = [];
  let flag: any = 0;
  for (let index = 0; index < data?.length; index++) {
    let Obj: any = {};
    Obj.name = data[index]?.name;
    // Obj.foods =
    //Obj.overLapImages
    // Obj.overLapImages = data[index]?.recommendations?.food?.eat?.nsoverlap;
    if (!_.isEmpty(data[index]?.recommendations?.food)) {
      Obj.foods = data[index]?.recommendations?.food;
      Obj.eat = !_.isEmpty(data[index]?.recommendations?.food?.eat)
        ? data[index]?.recommendations?.food?.eat
        : [];
      Obj.avoid = !_.isEmpty(data[index]?.recommendations?.food?.avoid)
        ? data[index]?.recommendations?.food?.avoid
        : [];
      arr.push(Obj);
    }
  }
  console.log("arr in foods data is--->", arr);
  if (arr?.length === 0) {
    return callBackMain([]);
  }

  let newArr = [...arr];

  for (let index = 0; index < newArr?.length; index++) {
    let arr = [];
    const ele = newArr[index]?.eat;

    for (let index2 = 0; index2 < ele?.length; index2++) {
      if (_.isEmpty(ele[index2]?.nsoverlap)) {
        arr.push([]);
      } else {
        arr.push(ele[index2]?.nsoverlap);
      }
    }

    newArr[index] = Object.assign({}, newArr[index], {
      overLapImages: arr,
    });
  }

  const combine1 = () => {
    for (let index = 0; index < newArr.length; index++) {
      const element: any = newArr[index];

      let data = element?.foodsEat;
      let recipes = element?.foodsRecipes;
      let overLapImages = element?.overLapImages;
      if (data && recipes && overLapImages) {
        let items = data?.map((dataId: any, index: number) => {
          return {
            data: dataId,
            recipes: recipes[index],
            overLapImages: overLapImages[index],
          };
        });
        let filteredData = _.filter(items, (item) => !_.isNil(item?.data));
        newArr[index] = Object.assign({}, newArr[index], {
          foodsEatCompleteData: filteredData,
        });
      }
    }

    for (let index = 0; index < newArr.length; index++) {
      const element: any = newArr[index];
      let data = element?.foodsAvoid;
      if (data) {
        let items = data?.map((dataId: any) => {
          return {
            data: dataId,
          };
        });
        let filteredData = _.filter(items, (item) => !_.isNil(item?.data));
        newArr[index] = Object.assign({}, newArr[index], {
          foodsAvoidCompleteData: filteredData,
        });
      }
    }

    // for (let index = 0; index < newArr?.length; index++) {
    //   // let Obj: any = {};
    //   // Obj.foods = {newArr[index].foodsEatCompleteData,};
    //   // Obj.foods = data[index]?.recommendations?.food;
    //   // //Obj.overLapImages
    //   // // Obj.overLapImages = data[index]?.recommendations?.food?.eat?.nsoverlap;
    //   // arr.push(Obj);
    // }

    for (let index = 0; index < newArr.length; index++) {
      const element: any = newArr[index];
      let foodsEat = element?.foodsCompleteData;
      let foodsAvoid = element?.foodsAvoid;
      let Obj: any = {};
      Obj.foodsEat = element?.foodsEatCompleteData
        ? element?.foodsEatCompleteData
        : [];
      Obj.foodsAvoid = element?.foodsAvoidCompleteData
        ? element?.foodsAvoidCompleteData
        : [];

      // if (foodsEat && foodsAvoid) {

      newArr[index] = Object.assign({}, newArr[index], {
        foodsCompleteData: Obj,
      });
      //}
    }

    let tempArr = [];
    for (let index = 0; index < newArr?.length; index++) {
      let element = newArr[index];
      let result = _.pick(element, ["name", "foods", "foodsCompleteData"]);

      tempArr.push(result);
    }

    return callBackMain(tempArr);
    // callBackMain(newArr);
  };

  //eat
  let itemsProcessed1 = 0;
  for (let index = 0; index < newArr?.length; index++) {
    await getFoodsData(
      newArr[index]?.eat,
      // eslint-disable-next-line no-loop-func
      function callBackFunction(resp: any) {
        itemsProcessed1++;
        newArr[index] = Object.assign({}, newArr[index], {
          foodsEat: resp,
        });
        if (itemsProcessed1 === newArr?.length) {
          flag++;
          if (flag === 3) {
            combine1();
          }
        }
      }
    );
  }

  //eat recipes
  let itemsProcessed2 = 0;
  for (let index = 0; index < newArr?.length; index++) {
    await getFoodsRecipes(
      newArr[index]?.eat,
      // eslint-disable-next-line no-loop-func
      function callBackFunction(resp: any) {
        itemsProcessed2++;

        newArr[index] = Object.assign({}, newArr[index], {
          foodsRecipes: resp,
        });
        if (itemsProcessed2 === newArr?.length) {
          flag++;

          if (flag === 3) {
            combine1();
          }
        }
      }
    );
  }

  let itemsProcessed3 = 0;
  //avoid
  for (let index = 0; index < newArr?.length; index++) {
    await getFoodsAvoidData(
      newArr[index]?.avoid,
      // eslint-disable-next-line no-loop-func
      function callBackFunction(resp: any) {
        itemsProcessed3++;

        newArr[index] = Object.assign({}, newArr[index], {
          foodsAvoid: resp,
        });

        if (itemsProcessed3 === newArr?.length) {
          flag++;

          if (flag === 3) {
            combine1();
          }
        }
      }
    );
  }
};

//array of recipes
const getFoodsRecipes = async (data: any, callBackRecipes: any) => {
  if (data?.length === 0) {
    return callBackRecipes([]);
  } else {
    let arr: any = [];
    let itemsProcessed = 0;
    /*asynchronous call added for optimization*/
    data?.forEach(async (element: any, index: number) => {
      const languageOfApp = sessionStorage.getItem("languageTypeOfApp");
      let result: any;
      result = await getRecipes(
        languageOfApp === "es-us"
          ? [element?.spanish_ingredient]
          : [element?.ingredient],
        index
      );

      if (result) {
        itemsProcessed++;
        arr[result?.index] = result?.response;
      } else {
        arr[result?.index] = result?.response;
        itemsProcessed++;
      }
      if (itemsProcessed === data?.length) {
        return callBackRecipes(arr);
      }
    });
  }
};

//array of foods
const getFoodsAvoidData = async (data: any, callBackFunction?: any) => {
  if (data?.length === 0) {
    return callBackFunction([]);
  } else {
    let arr: any = [];
    let itemsProcessed = 0;

    data?.forEach(async (element: any, index: number) => {
      let result: any;
      result = await getContent(element, index);
      if (result) {
        arr[result?.index] = result?.response;
        itemsProcessed++;
      } else {
        arr[result?.index] = result?.response;
        itemsProcessed++;
      }

      if (itemsProcessed === data?.length) {
        return callBackFunction(arr);
      }
    });
  }
};

//array of foods
const getFoodsData = async (data: any, callBackFunction?: any) => {
  if (data?.length === 0) {
    return callBackFunction([]);
  } else {
    let arr: any = [];
    let itemsProcessed = 0;

    data?.forEach(async (element: any, index: number) => {
      let result: any;
      result = await getContent(element?.lookup, index);
      if (result) {
        arr[result?.index] = result?.response;
        itemsProcessed++;
      } else {
        arr[result?.index] = result?.response;
        itemsProcessed++;
      }

      if (itemsProcessed === data?.length) {
        return callBackFunction(arr);
      }
    });
  }
};

//1 food item
const getContent = (contentTypeId: string, index: number) => {
  return getContentFromContentStack(contentTypeId)
    .then((response) => {
      if (response && response.error_message) {
        let Obj: any = {};
        Obj.response = undefined;
        Obj.index = index;
        return Obj;
        // console.log("single error is", response.error_message);
      } else {
        //return response && response[0];
        let Obj: any = {};
        Obj.response = response && response[0][0];
        Obj.index = index;
        return Obj;
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

//1 recipe
const getRecipes = (Id: any, index: number) => {
  return searchByIngredients(Id)
    .then((response: any) => {
      if (response && response.error_message) {
        let Obj: any = {};
        Obj.response = undefined;
        Obj.index = index;
        return Obj;
        // console.log("error is", response.error_message);
      } else {
        //return response && response;

        //return response && response;
        let Obj: any = {};
        // Obj.response = response?.data?.recipeSearch?.edges[0]?.node;
        Obj.response = _.sample(response?.data?.recipeSearch?.edges)?.node;
        Obj.index = index;
        return Obj;
      }
    })
    .catch((error: any) => {
      console.log(error);
    });
};
