export function foundationalArray(foundational_questions:any){
    
    const {
        basic_questions_1,
        basic_questions_2,
        basic_questions_3,
        nutrition_questions_1,
        // fitness_questions_1,
      } = foundational_questions || {};
      const array = [
        basic_questions_1,
        // fitness_questions_1,
        basic_questions_2,
        basic_questions_3,
        nutrition_questions_1,
      ];
    
    const arr = [];
    for (let i = 0; i < array.length; i++) {
      for (let j = 0; j < (array[i] && array[i].length); j++) {
        arr.push(array[i][j]._content_type_uid);
      }
    }
    return arr
}