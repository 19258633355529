//this function clear loacal & session storage when guest user time gets expired
export function clearSession(props: any) {
  let currentLanguage = sessionStorage.getItem("languageTypeOfApp") || "en-us"
  localStorage.clear();
  sessionStorage.clear();
  sessionStorage.setItem("session-cleared", "true");
  sessionStorage.setItem("languageTypeOfApp",currentLanguage)
  props.history.push("/home");
  localStorage.removeItem("guestExpiryTime");
}

export async function handleLoginSessionExpiry(){
  
    let currentLanguage = sessionStorage.getItem("languageTypeOfApp") || "en-us"
    localStorage.clear();
    sessionStorage.clear();
    sessionStorage.setItem("languageTypeOfApp",currentLanguage)
    localStorage.setItem("logout", "true");

}